// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 查询黑名单列表
export const queryBlackList = (data:any) => request('/payAdminServer/payBlackList/get', data, 'GET', null)

// 添加黑名单配置
export const addBlackSetting = (data:any) => request('/payAdminServer/payBlackList/add', data, 'POST_JSON', null)

// 修改黑名单配置
export const updateBlackSetting = (data:any) => request('/payAdminServer/payBlackList/update', data, 'POST_JSON', null)

// 删除黑名单配置
export const deleteBlackSetting = (data:any) => request('/payAdminServer/payBlackList/delete', data, 'POST_JSON', null)

